import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  background: black;
  color: white;
  z-index: 500;
  display: flex;
  justify-content: center;
  padding: 140px 0px 0px;

  p {
    position: relative;
    margin: 0px;
    user-select: none;
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    z-index: 100;
  }

  span {
    font-family: "Creepster";
    font-size: 75px;
    font-weight: 400;
  }
`;

export const InnerWrapper = styled.div`
  max-width: 600px;
`;
