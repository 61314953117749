import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Wrapper, Link, Line } from "./styled.jsx";

export const Contact = () => {
  return (
    <div>
      <Wrapper>
        <AnimatePresence initial={false}>
          <p>24 Maart</p>
          <p> PM voor locatie</p>
          <p>*BYOB*</p>
          <motion.section
            key="content"
            whileInView={{
              opacity: 1,
              duration: 0.1,
            }}
            transition={{ duration: 0.2, delay: 0.3 }}
            whileHover={{ opacity: 1 }}
            animate={{
              opacity: 0,
              x: 0,
              y: 0,
              scale: 1,
              rotate: 0,
            }}
          >
            <Line />
            <Link>
              <a
                href="https://www.instagram.com/joeymatulessy/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Instagram
              </a>
            </Link>
          </motion.section>
        </AnimatePresence>
      </Wrapper>
    </div>
  );
};

export default Contact;
