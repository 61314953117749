import styled from "styled-components";
import { device } from "../../helpers/breakpoints";

export const Wrapper = styled.div`
  background: black;
  bottom: 0px;
  z-index: 20;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 800px 0px 800px 0px;
  padding: 140px 0px 140px 0px;
  // @media ${device.laptop} {
  //   padding: 140px 0px 140px 0px;
  // }
`;

export const ArtistBlock = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background-color: rgba(18, 18, 18, 1);
  // background-size: 100% 100%;
  // background-position: 0px 0px;
  // background-image: linear-gradient(0deg, #ffffff00 1%, #858585ff 29%);
  font-weight: bold;
  font-size: 60px;
  padding: 30px 0px;
  cursor: pointer;
  user-select: none;

  @media ${device.laptop} {
    width: 700px;
    padding: 90px;
  }

  h5 {
    margin: 0px;
    padding: 23px 0px 0px 0px;
    @media ${device.laptop} {
      height: 52px;
    }

    font-weight: bold;
    letter-spacing: 7px;
  }
`;

export const Genre = styled.div`
  margin: 0px;
  padding-top: 5px;
  font-size: 28px;
  font-weight: normal;
  font-family: "Creepster";
`;

export const Time = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 20px;

  @media ${device.laptop} {
    position: absolute;
    left: -230px;
    top: 0px;
    font-size: 82px;
  }

  font-family: "Creepster";
  letter-spacing: 7px;
`;

export const TimeEnd = styled.div`
  position: absolute;
  right: 7px;
  top: 10px;
  font-size: 20px;

  @media ${device.laptop} {
    position: absolute;
    right: -230px;
    top: 0px;
    font-size: 82px;
  }

  color: rgba(255, 255, 255, 0.6);
  font-family: "Creepster";
  letter-spacing: 7px;
`;

export const ArtistInfo = styled.p`
  font-size: 60px;
  padding: 30px 32px;
  margin: 0px;
  text-align: left;
  word-break: break-word;
  @media ${device.laptop} {
    padding: 20px 0px 0px 0px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin-top: 20px;
`;

export const CircleAni = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 80;
  // mix-blend-mode: screen;

  pointer-events: none;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${(props) => props.progress && `${props.progress}%`};

  height: 3px;
  background-color: white;
`;
