import styled from "styled-components";
import { device } from "../../helpers/breakpoints";

export const VideoOverlay = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  overflow: hidden;
  z-index: 100;
`;

export const VideoOverlay__Inner = styled.div`
  position: absolute;
  width: 3000px;
  height: 100vh;
  background: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const Info = styled.div`
  overflow: hidden;
  mix-blend-mode: overlay;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 130;
  ${(props) =>
    props.hover ? "mix-blend-mode: normal;" : "mix-blend-mode: overlay;"};
`;

export const Title = styled.div`
  // mix-blend-mode: overlay;
  // transform: rotate(90deg);
  // @media ${device.laptop} {
  //   transform: rotate(0deg);
  // }
`;

export const Subtitle = styled.div`
  top: -100px;
  color: white;
  font-size: 20vw;
  z-index: 130;
  letter-spacing: 11px;

  @media ${device.laptop} {
    position: relative;
    font-size: 12vw;
  }
`;

export const Wrapper = styled.div`
  z-index: 450;
  // mix-blend-mode: overlay;
`;
