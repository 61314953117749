import React from "react";
import { Banner } from "./components/banner/banner.jsx";
import { Header } from "./components/header/header.jsx";
import { Footer } from "./components/footer/footer.jsx";
import { Timetable } from "./components/timetable/timetable.jsx";
import { Contact } from "./components/contact/contact.jsx";
import { Description } from "./components/description/description.jsx";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header>
        <Header />
      </header>
      <Banner />
      <Description />
      <Timetable />
      <Contact />
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
