import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 140px;
  background: black;
  bottom: 0px;
  z-index: 500;
`;
