import styled from "styled-components";
import { device } from "../../helpers/breakpoints";

export const Wrapper = styled.div`
  width: 100vw;
  background: black;
  bottom: 0px;
  z-index: 20;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 300px 0px;
  height: 46vh;
  p {
    font-size: 60px;
    font-family: "Creepster";
    z-index: 150;
  }

  @media ${device.laptop} {
    padding: 0px 0px 650px 0px;
    height: 20vh !important;

    p {
      font-size: 4rem;
      max-width: 70vw;
    }
  }
`;

export const Link = styled.div`
  a {
    color: white;
    text-decoration: none;
  }
`;
export const Time = styled.div`
  position: absolute;
  left: -100px;
  margin-right: 10px;
`;

export const ArtistInfo = styled.div`
  width: 100%;
  padding: 20px 0px 0px 0px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin-top: 20px;
`;
