import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useWindowSize } from "../../helpers/useWindowSize";
import { sizeNumber } from "../../helpers/breakpoints";

import {
  Wrapper,
  ArtistBlock,
  Time,
  ArtistInfo,
  Line,
  CircleAni,
  ProgressBar,
  TimeEnd,
  Genre,
} from "./styled.jsx";

const artists = [
  {
    name: "Mental Leo",
    startTime: "22:00",
    startHour: 22,
    endTime: "23:00",
    genre: "House",
    bgMode: 0,
    description:
      "Student aan huis, brengt mellow house beats in de snelste wijze.",
  },
  {
    name: "DJordy",
    startTime: "23:00",
    startHour: 23,
    endTime: "00:00",
    genre: "House",
    bgMode: 1,
    description: "Festivals, clubs, en nu in een loods; Inclusief live set.",
    scUrl: "https://soundcloud.com/fnk4",
  },
  {
    name: "Beganie & Legendary",
    startTime: "00:00",
    startHour: 24,
    endTime: "01:00",
    genre: "Dubstep",
    bgMode: 0,
    description:
      "Opkomend, getalenteerd en golvenmakend in de elektronische scene. Deze man onderscheid zich door passie, en fking diepe harde wobbels. ",
  },
  {
    name: "AABEL",
    startTime: "01:00",
    startHour: 1,
    endTime: "02:00",
    genre: "Techno, House",
    bgMode: 1,
    description: "Pro-doucher, anti-concept, sí?",
  },
  {
    name: "Botervet",
    startTime: "02:00",
    startHour: 2,
    endTime: "03:00",
    genre: "Dubstep, DnB",
    description: "Lorax ipsum, consectetur elit.",
    bgMode: 0,
  },
  {
    name: "Lucardius",
    startTime: "03:00",
    startHour: 3,
    endTime: "04:00",
    genre: "Dubstep, DnB",
    description: "Broeder DnB; Poëet op de decks. Wederhelft van Gooi Jezelf.",
    bgMode: 1,
  },
  {
    name: "TBA",
    startTime: "04:00",
    startHour: 4,
    endTime: "05:00",
    genre: "",
    description: "Jatoch; Ff nog wat meer planning nodig.",
    bgMode: 0,
  },
  {
    name: "FFA",
    startTime: "05:00",
    startHour: 4,
    endTime: "06:00",
    genre: "You do you",
    description: "Kom maar lekker viben. -K-Liber",
    bgMode: 1,
  },
];

export const Timetable = () => {
  const [dateState, setDateState] = useState(new Date());
  const [open, setOpen] = useState(null);
  const dimensions = useWindowSize();

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 10000);
  }, []);

  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: () => {
      // const delay = 1 + i * 0.5;
      const delay = 1;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };

  const isLaptop = dimensions.width < sizeNumber.laptop;
  return (
    <div>
      <Wrapper>
        {artists.map((artist, index) => {
          const isHour = dateState.getHours() === artist.startHour;

          return (
            <>
              <motion.div
                key={index}
                style={{
                  display: "flex",
                  width: isLaptop ? "100%" : "auto",
                }}
                whileInView={{
                  opacity: isHour ? 1 : open === index ? 1 : 0.1,
                  duration: 0.1,
                }}
                whileHover={{ opacity: open === index ? 1 : 0.8 }}
              >
                <ArtistBlock
                  style={{ zIndex: 180 }}
                  onClick={() => setOpen(open === index ? null : index)}
                >
                  {isHour && (
                    <ProgressBar
                      progress={dateState.getMinutes() * (100 / 60)}
                    />
                  )}
                  <Time>{artist.startTime}</Time>
                  {isHour && isLaptop && <TimeEnd>{artist.endTime}</TimeEnd>}
                  <h5>{artist.name}</h5> <Genre>{artist.genre}</Genre>
                  <AnimatePresence initial={false}>
                    {open === index && (
                      <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                          open: { opacity: 1, height: "auto" },
                          collapsed: { opacity: 0, height: 0 },
                        }}
                        transition={{
                          duration: 1.2,
                          delay: 0.2,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <Line />
                        <ArtistInfo>{artist.description}</ArtistInfo>
                      </motion.section>
                    )}
                  </AnimatePresence>
                </ArtistBlock>

                <AnimatePresence initial={true} style={{ zIndex: 180 }}>
                  {open === index && artist.bgMode === 1 && (
                    <CircleAni>
                      <motion.svg
                        width="600"
                        height="600"
                        viewBox="0 0 300 300"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                      >
                        <motion.circle
                          cx="150"
                          cy="150"
                          r="130"
                          stroke="#ff0055"
                          variants={draw}
                          custom={2}
                        />
                      </motion.svg>
                    </CircleAni>
                  )}
                  {open === index && artist.bgMode === 0 && (
                    <CircleAni>
                      <motion.svg
                        width="800"
                        height="800"
                        viewBox="0 0 300 300"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                      >
                        <motion.line
                          x1="75"
                          y1="75"
                          x2="300"
                          y2="300"
                          stroke="#00cc88"
                          variants={draw}
                          custom={2}
                        />
                        <motion.line
                          x1="75"
                          y1="190"
                          x2="190"
                          y2="70"
                          stroke="#00cc88"
                          variants={draw}
                          custom={2.5}
                        />
                      </motion.svg>
                    </CircleAni>
                  )}
                </AnimatePresence>
              </motion.div>
            </>
          );
        })}
      </Wrapper>
    </div>
  );
};

export default Timetable;
