import React, { useState } from "react";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import { useWindowSize } from "../../helpers/useWindowSize";
import { sizeNumber } from "../../helpers/breakpoints";

import {
  VideoOverlay,
  VideoOverlay__Inner,
  Info,
  Title,
  Wrapper,
  Subtitle,
} from "./styled.jsx";

export const Banner = () => {
  const [hover, setHover] = useState(false);
  const dimensions = useWindowSize();

  const isLaptop = dimensions.width < sizeNumber.laptop;
  return (
    <Wrapper>
      <Info hover={isLaptop ? true : hover}>
        <Title>
          <motion.article initial="hidden" animate="visible">
            <motion.div
              transition={{
                duration: 0.8,
                ease: [0.36, 0, 0.66, -0.56],
              }}
              whileHover={{
                scale: 0.3,
                color: [
                  "rgba(255, 255, 255, 0.1)",
                  "#09F",
                  "#FA0",
                  "#FA0",
                  "rgba(0, 0, 0, 0.8)",
                ],
              }}
              whileInView={{
                scale: isLaptop ? 0.3 : 1,
                color: isLaptop
                  ? [
                      "rgba(255, 255, 255, 0.1)",
                      "#09F",
                      "#FA0",
                      "#FA0",
                      "rgba(0, 0, 0, 0.8)",
                    ]
                  : "rgba(0, 0, 0, 1)",
              }}
              onHoverStart={() => {
                setHover(true);
              }}
              onHoverEnd={() => {
                setHover(false);
              }}
              style={{
                fontSize: isLaptop ? "110vw" : "100vw",
                fontFamily: "'Creepster', cursive",
                userSelect: "none",
                color: "rgba(255, 255, 255, 0.8)",
                mixBlendMode: "exclusion",
                letterSpacing: isLaptop ? 30 : 100,
              }}
              variants={{
                hidden: { opacity: 0, y: -50 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.2, duration: 0.8 },
                },
              }}
            >
              Joey&apos;s
              <Subtitle>
                30&apos;ste jaar in het verjaren van jaren jaarfeest
              </Subtitle>
            </motion.div>
          </motion.article>
        </Title>
      </Info>

      {/* <motion.div
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 1.1 }}
        drag="x"
        dragConstraints={{ left: -100, right: 100 }}
      >
        <svg id="logo">
          <text x="50%" y="50%" fill="transparent" textAnchor="middle">
            30
          </text>
        </svg>
      </motion.div> */}
      <VideoOverlay>
        <VideoOverlay__Inner>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=V9H0F0pfLNM"
            width="100%"
            height="100%"
            playing={true}
            loop={true}
            controls={false}
            muted={true}
            pip={false}
          />
        </VideoOverlay__Inner>
      </VideoOverlay>
    </Wrapper>
  );
};

export default Banner;
