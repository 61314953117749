import React from "react";
import { motion } from "framer-motion";
import { Wrapper, InnerWrapper } from "./styled.jsx";

export const Description = () => {
  return (
    <Wrapper>
      {/* <p>A crazy decade to celebrate spiritually bizarre</p> */}
      <InnerWrapper>
        <motion.div
          style={{
            opacity: 0.0,
          }}
          whileInView={{
            opacity: 1,
            duration: 2,
          }}
          transition={{
            delay: 0.4,
          }}
        >
          <p>
            20+ -&gt; 30+ <br />
            {/* <br />
            Een nieuw{" "}
            <span
              style={{
                borderBottom: "5px solid white",
              }}
            >
              decennium
            </span>{" "}
            om spiritueel bizar te vieren. */}
          </p>
        </motion.div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Description;
